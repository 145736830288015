import { render, staticRenderFns } from "./GnomeModal.vue?vue&type=template&id=6112c0c2&scoped=true"
import script from "./GnomeModal.vue?vue&type=script&lang=js"
export * from "./GnomeModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6112c0c2",
  null
  
)

export default component.exports